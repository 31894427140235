import type { FunctionComponent } from 'react';
import type { TypeProseProps } from './types';

/**
 * El componente prose da estilos al texto rico que
 * se recibe desde un CMS.
 */
export const Prose: FunctionComponent<TypeProseProps> = ({
  className = '',
  as: HTMLTag = 'div',
  html,
}: TypeProseProps) => (
  <HTMLTag
    className={`prose max-w-full text-inherit [&.prose_:where(a):not(:where([class~="not-prose"]_*))]:!text-secondary-500 ${className}`}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

Prose.displayName = 'Prose';
